import React from 'react'
import SEO from '../components/seo'
import Layout from '../components/layout'
import ContentBlock from '../components/ContentBlock/ContentBlock'
import PageTitle from '../components/PageTitle/PageTitle'
import { Container, Row, Col } from 'react-bootstrap'
import { graphql } from 'gatsby'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import PostLinks from '../components/PostLinks'
import CrestPlaceholderImg from '../components/NewsBlock/CrestPlaceHolderImg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons'



const PageTemplate = ({ data, pageContext }) => {
    // console.log(data)
    const post = data.contentfulNewsPost
    //const embed = data.iframeEmbed
    const previous = pageContext.prev
  const next = pageContext.next
    //const { previous, next } = this.props.pageContext
    //console.log(post.childContentfulNewsPostNewsContentRichTextNode.json)
    //console.log(embed)

    const options = {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: ({data: { target: { fields }}}) => 
                <div dangerouslySetInnerHTML={{__html: `<img src="${fields.file['en-US'].url}" alt="${fields.title['en-US']}" class="fluid" style="width: 100%; margin: 20px 0 20px 0" />` }} />,

                [BLOCKS.EMBEDDED_ENTRY]: (node) => {
                    const fields = node.data.target.fields.imageLink;
                    const imgLnk = node.data.target.fields.url;
                    console.log(fields, imgLnk)
                        return (
                        <div dangerouslySetInnerHTML={{__html: `<a href="${imgLnk['en-US']}"><img src="${fields['en-US'].fields.file['en-US'].url}" alt="${fields.title}" style="width: 100%" /></a>` }} />
                        )
                    },
                
                // Embed iframe content
                [INLINES.EMBEDDED_ENTRY]: (node) => {
                    const embed = node.data.target.fields.markdownContent;
                    console.log('embed data: ' + embed['en-US'])
                    return (
                        <div dangerouslySetInnerHTML={{__html: `${embed['en-US']}`}} />
                    )
                }
        }
    }

    return (
        <Layout>
        <SEO title={post.title}/>
        <Container fluid className="container-fluid-full">
          <PageTitle pageTitle={post.title} >
            {/* {page.pageTitle} */}
          </PageTitle>
          <ContentBlock>
            <Row>
                <Col sm={9}>
                <div>
    <p><strong>{post.createdAt}</strong></p>
    <div>
                    <strong>
                    <a href={post.linkToOriginalArticle} target="_blank" rel="noopener noreferrer" style={{fontSize: '1.5rem'}}>Read original article <FontAwesomeIcon
                    icon={faAngleRight}
                    style={{
                        
                        marginRight: '5px',
                    }}
                    aria-hidden="true"
                    
                /></a>
                    </strong>
                </div>
                    {documentToReactComponents(post.childContentfulNewsPostPostContentRichTextNode.json, options)}
                </div>
                {/* <div dangerouslySetInnerHTML={{__html: embed.edges[0].node.childMarkdownRemark.html}}></div> */}
            
                </Col>
                {!post.image ? <Col sm={3}><CrestPlaceholderImg /></Col> : (
              <Col sm={3}>
              <a href={post.linkToOriginalArticle} target="_blank" rel="noopener noreferrer">
                  <img style={{
                      width: '100%'
                    }}
                  src={post.image ? post.image.fluid.src : null } alt={post.title} className="rounded" />
              </a>
              <p className="featuredImageCaption">{post.image.description}</p>
       
          </Col> ) }

                {/* <div>

            <img src={post.image ? post.image.fluid.src : null} 
                style={{ width: '100%', marginBottom: '20px'}}
                className="rounded mx-auto d-block"    
            />
            <p className="featuredImageCaption">{post.image.description}</p>
            </div>
                </Col> */}
            </Row>
            
            <PostLinks previous={previous} next={next}  />
            </ContentBlock>
            </Container>
        </Layout>
    )
}

export const getBlogPost = graphql`
query ContentfulBlogPostBySlug($slug: String) {
    contentfulNewsPost(slug: { eq: $slug }) {
        title
        slug
        updatedAt(formatString: "dddd MMMM Do, YYYY")
        childContentfulNewsPostPostExcerptTextNode {
          postExcerpt
        }
        childContentfulNewsPostPostContentRichTextNode {
          json
        }
        image: featuredImage {
          file {
            url
          }
          title
          description
          fluid(maxWidth: 800) {
            sizes
            src
            srcSet
          }
        }
        linkToOriginalArticle
        createdAt(formatString: "ddd MMM Do, YYYY")
      }
    }
`

export default PageTemplate